import { components } from '@/lib/helpers.js'

//
// Fonts
//
import '@fontsource/open-sans/variable.css'

//
// Styles
//
import '../css/style.css'

//
// Initialize components
//
components({
    navigation: import('@/components/navigation.js'),
})
